html {
  height: 100%;
}
body {
height: 100%;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.layout {
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0 auto;
  height: 100%;
  position: relative;
  background: url("./images/background.jpg") no-repeat fixed center;
  background-size: cover;
  background-position-y: 45px;
}
.section {
  width: 96%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  align-items: center;
}
.section__title {
  font-weight: 600;
  font-size: 28px;
  line-height: 1.3;
  color: #2f42c2;
}
.section__link {
  z-index: 5;
  position: absolute;
  bottom: 15px;
  right: 30px;
  background-color: transparent;
  outline: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: #121759;
  backdrop-filter: blur(1px);
  padding: 3px 10px;
  border-radius: 5px;
  overflow: hidden;
}
.content {
  width: 100%;
  display: grid;
  grid-template-rows: 67px 1fr;
  align-items: center;
  margin-bottom: 20px;
}

.button {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  max-width: 260px;
  height: 52px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 28px auto 20px;
}

.modals {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 100;
}

.lock-scroll {
  max-height: 100vh;
  overflow: hidden;
}
html::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
/* Handle */
html::-webkit-scrollbar-thumb {
  opacity: 0.4;
  background: #eae6df;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a19f9f;
}
input,
button,
textarea {
  font-family: inherit;
}
.error {
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
}
.loader {
  visibility: hidden;
}
.loader_visible {
  visibility: visible;
}

@media screen and (max-width: 1300px) {
  .section__title {
    font-size: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .button {
    width: 230px;
    height: 45px;
  }
}
@media screen and (max-width: 1024px) {
  .error {
    font-size: 14px;
  }
}
