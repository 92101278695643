.questionnaire {
  margin-top: 66px;
  display: grid;
  grid-template-columns: 117px 323px;
  justify-items: center;
  gap: 8px;
  text-decoration: none;
  direction: rtl;
}
.questionnaire_small {
  direction: ltr;
  display: flex;
  gap: 5px;
  position: fixed;
  bottom: 59px;
  left: 23px;
  text-decoration: none;
  overflow: hidden;
  width: 93px;
  height: 91px;
  animation: blink 8s ease-in-out infinite;
  z-index: 2;
  transition: all 1s;
}
.questionnaire_small:hover {
  width: 290px;
  animation: none;
}
.questionnaire__answer_small {
  white-space: nowrap;
  background-color: #6386f9;
  color: #fff;
  padding: 12px 30px 12px 12px;
  border-radius: 30px;
  position: relative;
}
.questionnaire__answer_small:before {
  content: "";
  width: 20px;
  height: 17px;
  position: absolute;
  top: 32%;
  right: 8px;
  color: #fff;
  background-image: url("../../images/arrow_left_white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  rotate: 180deg;
}
.questionnaire__answer_small:after {
  content: "";
  width: 20px;
  height: 17px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url("../../images/answer_bg_tail.png");
  z-index: 2;
  background-repeat: no-repeat;
  background-size: cover;
}
.questionnaire__doctor {
  max-width: 100%;
}
.questionnaire__answer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 323px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #6386F9;
  border-radius: 32px;
  padding: 12px 49px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1;
  color: #ffffff;
  margin: auto;
  min-height: 74px;
  overflow-wrap: anywhere;
  position: relative;
}
.questionnaire__answer:before {
  content: "";
  background-image: url("../../images/arrow_left_white.svg");
  height: 11px;
  width: 6px;
  position: absolute;
  left: 20px;
  top: 42%;
}
.questionnaire__answer:after {
  content: "";
  background-image: url("../../images/questionnaire_tail.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 29px;
  width: 33px;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 1400px) {
  .questionnaire {
    grid-template-columns: 103px 285px;
  }
  .questionnaire_small {
    width: 83px;
    height: 81px;
    bottom: 26px;
  }
  .questionnaire_small:hover {
    width: 280px;
  }
  .questionnaire__answer_small {
    padding: 10px 25px 10px 10px;
    border-radius: 20px;
  }
  .questionnaire__answer_small:before {
    width: 12px;
    height: 10px;
    top: 38%;
    right: 6px;
  }
  .questionnaire__answer_small:after {
    width: 18px;
    height: 15px;
  }
  .questionnaire__answer {
    font-size: 18px;
    min-height: 63px;
  }
  .questionnaire__answer_small {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .questionnaire {
    grid-template-columns: 75px 234px;
    gap: 6px;
  }
  .questionnaire__answer {
    width: 233px;
    padding: 6px 27px;
    font-size: 16px;
    line-height: 1.5;
    min-height: 66px;
  }
  .questionnaire_small {
    width: 40px;
    height: 40px;
    direction: rtl;
    right: 10px;
    bottom: 10px;
    left: unset;
  }
  .questionnaire__answer_small {
    height: 20px;
    font-size: 12px;
    margin: auto 0;
    line-height: 1;
    padding: 10px 5px 5px 15px;
  }
  .questionnaire__answer_small:before {
    width: 10px;
    height: 8px;
    top: 38%;
    left: 4px;
    right: unset;
    rotate: none;
  }
  .questionnaire__answer_small:after {
    width: 20px;
    height: 10px;
    left: unset;
    right: 3px;
  }
}

@keyframes blink {
  44% {
    opacity: 1;
  }
  45% {
    opacity: 0.5;
  }
  48% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  61% {
    opacity: 1;
  }
}
