.pagination{
    margin: 0 auto;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 17px;
    list-style-type: none;
    padding: 0;
}
.page{
    cursor: pointer;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 100%;
}
.page_active{
    background-color: #2F42C2;
}