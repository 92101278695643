.chatPage{
    width: 100vw;
    height: 100%;
    max-height: 100%;
    display: flex;
    gap: 15px;
}
.noReceiver{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}