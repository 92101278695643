.myDay {
  margin: 33px auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
.myDay__title {
  margin: 0 0 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #2f42c2;
  width: 50%;
}
.myDay__title:first-child {
  margin-bottom: 10px;
}
.myDay__section {
  position: relative;
  width: 592px;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  margin: 33px 0 7px;
  color: #2f42c2;
}
.nextBtn{
  width: 9px;
  height: 17px;
  position: absolute;
  top: 45%;
  left: -40px;
  background-image: url("../../images/arrow_left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.myDay__sectionTitle {
  position: absolute;
  margin: 0;
  top: -20px;
  left: calc((100% - 148px) / 2);
  width: fit-content;
  height: 41px;
  background: #006cc9;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 18px;
  box-sizing: border-box;
}
.notifications {
  display: flex;
  gap: 14px;
  box-sizing: border-box;
  padding: 29px 19px 23px;
}
.notifications__venchy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notifications__img {
  width: 56px;
  height: 56px;
}
.notifications__name {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}
.notifications__date {
  margin: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
}
.notifications__text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  flex: 0 0 100%;
}

@media screen and (max-width: 1400px) {
  .myDay__title {
    margin: 0 auto 21px;
    font-size: 24px;
  }
  .myDay__section {
    width: 507px;
    margin-top: 40px;
    border-radius: 20px;
  }
  .myDay__sectionTitle {
    top: -17px;
    height: 35px;
    font-size: 16px;
    padding: 7px 15px;
    left: calc((100% - 131px) / 2);
  }
  .notifications {
    padding: 25px 19px 9px;
  }

  .notifications__text {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 800px) {
  .myDay {
    width: calc(100% - 50px);
  }
  .myDay__section {
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.07);
    margin-top: 62px;
    margin-bottom: 13px;
  }
  .myDay__section:first-of-type {
    margin-top: 40px;
  }
  .myDay__sectionTitle {
    top: -23px;
    font-size: 16px;
  }
}
@media screen and (max-width: 700px) {
  .myDay__section{
    width: calc(100% - 60px)
  }
  .myDay__title{
    font-size: 23px;
  }
  .nextBtn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .myDay__title{
    width: 80%  ;
  }

}
@media screen and (max-width: 500px) {
  .myDay__section{
    width: 100%;
  }

  .notifications {
    flex-direction: column;
    padding-top: 34px;
    gap: 8px;
  }
  .notifications__venchy {
    flex-direction: row;
    justify-content: center;
  }
  .notifications__img {
    width: 38px;
    height: 38px;
  }


}