.popupWindow {
  margin: auto auto;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 0 21px -5px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  max-height: 94%;
  overflow-y: auto;
  padding: 19px;
  direction: rtl;
}
.popupWindow_type_small{
  max-width: 300px;
}
.popupWindow::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.popupWindow::-webkit-scrollbar-track {
  /*background: transparent;*/
}

/* Handle */
.popupWindow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.popupWindow__closeBtn {
  width: 16px;
  height: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../images/close_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px 10px 0 auto;
}
@media screen and (max-width: 1400px) {
  .popupWindow {
    border-radius: 20px;
  }
  .popupWindow__closeBtn {
    width: 12px;
    height: 12px;
    margin: 4px 4px 0 auto;
  }
}
@media screen and (max-width: 600px) {
}