.note{
    margin: 0 auto;
    font-family: inherit;
    font-size: small;
    font-weight: 600;
    font-style: oblique;
    line-height: 1;
    background: rgba(242, 247, 252, 0.87);
    color: #626262;
    padding: 8px 20px;
    border-radius: 6px;
    max-width: 90%;
}