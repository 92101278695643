.popup {
    width: 36vw !important;
    min-height: 539px;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
}
.popup__closeBtn {
    width: 16px;
    height: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    background-image: url("../../images/close_icon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin: 24px 24px 0 auto;
}
.popup__title {
    margin: 0 auto 59px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
    color: #2f42c2;
}
.popup__img {
    width: 145px;
    height: 145px;
    margin: 61px auto 35px;
}
.popup__text {
    max-width: 344px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    color: #000000;
}
.popup__nextBtn {
    margin: 0 auto 27px;
    width: 260px;
    height: 57px;
    background: #5d3cc7;
    border: 1px solid #e1e1e1;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.11;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}
@media screen and (max-width: 1300px) {
    .popup {
        width: 405px;
        min-height: 442px;
    }
    .popup__closeBtn {
        width: 12px;
        height: 12px;
        margin: 20px 20px 0 auto;
    }
    .popup__title {
        margin-bottom: 43px;
        font-size: 26px;
    }
    .popup__img {
        width: 119px;
        height: 117px;
        margin: 57px auto 28px;
    }
    .popup__text {
        max-width: 244px;
        font-size: 19px;
    }
    .popup__nextBtn {
        width: 213px;
        height: 47px;
        font-size: 16px;
    }

}
@media screen and (max-width: 600px) {
    .popup {
        width: 301px;
        min-height: 329px;
    }
    .popup__title {
        font-size: 19px;
        margin-bottom: 32px;
    }
    .popup__text {
        font-size: 15px;
        margin-bottom: 43px;
    }
    .popup__img {
        width: 88px;
        height: 87px;
        margin: 0 auto;
    }
    .popup__nextBtn {
        width: 159px;
        height: 35px;
        font-size: 12px;
        margin-top: 21px;
    }
}
