.terms {
  font-size: 18px;
  width: 90%;
  margin: 0 auto;
  width: 90vw;

}
.terms__logo {
  width: 260px;
  margin: 0 auto;
  display: block;
}
.terms__list {
  list-style-type: decimal;
}
.terms__li {
  margin-top: 10px;
  line-height: 1.2;
}
.terms__title {
}
.terms__title_main {
  margin: 20px auto 0;
  text-decoration: underline;
  text-align: center;
  color: #121759;
}
@media screen and (max-width: 1200px) {
  .terms {
    font-size: 16px;
  }

  .terms__logo {
    width: 200px;
  }
  .terms__title_main {
    margin: 18px auto 0;
  }
}
