.window {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 90;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoContainer {
  width: 90%;
  height: 90%;
  border-radius: 15px;
  border: 1px solid #7d91aa;
  box-shadow: 0 3px 119px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.header {
  color: #fff;
  direction: rtl;
  margin: 20px 40px;
}
.userVideoWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
}
.userVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: unset;
  height: 90%;
  max-height: 90%;
  border-radius: 15px;
  border: none;
  margin-bottom: 0;
}
.myVideo {
  height: 180px;
  width: 120px;
  top: 96px;
  right: 2%;
  background-color: #000;
  border-radius: 15px;
  position: absolute;
  border: 1px solid #7d91aa;
  object-fit: cover;
}
.buttonsBar {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
  justify-content: center;
  position: absolute;
  bottom: 35px;
  list-style-type: none;
}
.btn {
  background-color: transparent;
  outline: none;
  border: none;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn__image{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/*.btn_type_flipCamera {*/
/*  background-image: url("../../images/flip_camera_icon.svg");*/
/*}*/
.btn_type_mute {
  background-image: url("../../images/microphone_slash_icon.svg");
  width: 42px;
  display: block;
  background-color: #d1d1d1;
  height: 42px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px 26px;
}
.btn_type_unmute{
  background-image: url("../../images/microphone_icon.svg");
}
/*.btn_type_acceptCall {*/
/*  background-image: url("../../images/answer_call_icon.svg");*/
/*  transform: rotate(-137deg);*/
/*}*/
/*.btn_type_endCall {*/
/*  background-image: url("../../images/end_call_icon.svg");*/
/*}*/
.avatar{
  position: absolute;
  margin: auto;
  width: 74px;
  height: 74px;
  border-radius: 100%;
}
.outgoingCall{
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-image: url("../../images/video_icon.svg");
  background-color: #33a3ff;
  background-repeat: no-repeat;
  background-size: 44px 32px;
  background-position: center;
  margin-bottom: 100px;
}
.pulse{
  height:74px;
  width:74px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
}

.pulse::before{
  content:"";
  position:absolute;
  border:1px solid #33a3ff;
  width:calc(100% + 40px);
  height:calc(100% + 40px);
  border-radius:50%;
  animation:pulse 1s linear infinite;
}

.pulse::after{
  content:"";
  position:absolute;
  border:1px solid #33a3ff;
  width:calc(100% + 40px);
  height:calc(100% + 40px);
  border-radius:50%;
  animation:pulse 1s linear infinite;
  animation-delay:0.3s;
}

@media screen and (max-width: 680px) {
  .window {
    background-color: rgba(0, 0, 0, 1);
    height: 100%;
  }

  .videoContainer {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .myVideo {
    top: unset;
    bottom: 13%;
  }

  .userVideoWindow {
    height: 100%;
  }

  .userVideo {
    height: 100%;
    max-height: unset;
  }

  .buttonsBar {
    bottom: 6px;
  }

  .btn {
    height: 95px;
    width: 95px;
  }

  .btn_type_mute {
    width: 52px;
    height: 52px;
  }
}
.btn_type_video{
  background-image: url("../../images/video_icon_toggle_active.svg");
}
.btn_type_video_inactive{
  background-image: url("../../images/video_icon_toggle_inactive.svg");
}

@keyframes pulse{
  0%{
    transform:scale(0.5);
    opacity:0;
  }
  50%{
    transform:scale(1);
    opacity:1;
  }
  100%{
    transform:scale(1.3);
    opacity:0;
  }
}