.popup__title{
    margin-bottom: 4px;
}
.popup__img {
    width: 75px;
    height: 75px;
    margin: 10px auto;
}
.popup__text{
    width: 100%;
    margin: 6px 0 0;
    font-size: 17px;
    text-align: start;
}

@media screen and (max-width: 1300px) {
    .popup__text{
        margin: 4px 0 0;
        font-size: 16px;
    }

}
