.note{
    direction: rtl;
    display: flex;
    align-items: baseline;
    gap: 4px;
    margin: 0 auto;
    font-family: inherit;
    font-size: small;
    font-style: oblique;
    line-height: 1;
    color: #626262;
}
.loader {
    width: 12px;
    height: 5px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, rgba(98, 98, 98, 0.83) 100%,#0000) 0/calc(80%/3) 100% space;
    clip-path: inset(0 0 0 100%);
    animation: l1 2s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -4% 0 -34% )}}