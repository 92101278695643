.section {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 34px 0 35px 10px;
    box-sizing: border-box;
    width: 96%;
    gap: 36px;
    max-width: 1300px;
    max-height: 75vh;
    overflow: auto;
    margin-bottom: 10px;
}
.section_POC{
    max-height: 85vh;
    padding-right: 30px;
}
.section::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}
/* Handle */
.section::-webkit-scrollbar-thumb {
    border-radius: 5px;
    opacity: 0.4;
    background: rgba(251, 242, 253, 0.62);
}
.section::-webkit-scrollbar:horizontal {
    display: none;
}
/* Handle on hover */
.section::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    opacity: 0.4;
    background: #2f42c2;
}
.variants {
    list-style-type: none;
    margin: 0;
    width: fit-content;
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 11px;
}
.variants_poc{
    align-self: flex-end;
}
.variant {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    padding: 4px 16px 5px;
    background: #ffffff;
    border-radius: 86px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #121759;
    text-align: right;
    direction: rtl;
    min-width: 100px;
    border: 1px solid #e1e1e1;
}
.link {
    text-decoration: none;
    direction: ltr;
}
.number {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 27px;
    color: #5d3cc7;
    margin-left: 22px;
}
.input {
    width: 100%;
    border: 0;
    padding-left: 30px;
    cursor: pointer;
}
.textArea {
    overflow: hidden;
    cursor: pointer;
}
.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 40%;
    margin-right: 20px;
    cursor: pointer;
}
.inputContainer_position_left {
    margin: 10px 20px 0 auto;
}
.sendBtn {
    cursor: pointer;
    position: absolute;
    left: 10px;
    width: 14px;
    height: 12px;
    outline: none;
    border: none;
    background-color: transparent;
    background-image: url("../../images/chat_send_icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
}
.typing{
    font-style: italic;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 0;
    color: #a19f9f;
}
@media screen and (max-width: 1300px) {
    .section {
        gap: 11px;
    }
    .variants {
        gap: 11px;
    }
    .variant {
        border-radius: 30px;
        font-size: 16px;
        line-height: 1.2;
    }
    .number {
        margin-left: 18px;
    }
    .inputContainer {
        width: 50%;
        padding-bottom: env(safe-area-inset-bottom); /* For iOS safe area */
    }
}
@media screen and (max-width: 1024px) and (min-width: 600px) {
    .variant {
        font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
    .section {
        max-height: 85vh;
    }
    .inputContainer {
        font-size: 16px;
        width: 80%;
    }
}
