.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 293px;
  background: rgba(231, 242, 253, 0.6);
  border-left: 1px solid #c9c9c9;
  border-radius: 55px 0 0 0;
  z-index: 4;
  transition: all 0.7s;
}
.scrollable {
  overflow-y: auto;
  max-height: 100%;
}
.sidebar__therapists {
  padding: 0;
  margin: 51px 0 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: rtl;
}
.sidebar__li {
  margin-top: 70px;
  cursor: pointer;
  width: 100%;
  height: 73px;
  border-bottom: 1px solid #d1d1d1;
  display: grid;
  grid-template-columns: 57px 1fr 32px;
  box-sizing: border-box;
  padding: 13px 15px 10px 10px;
  gap: 16px;
  background-color: #cee7ff;
}
.sidebar__online {
  position: relative;
}
.sidebar__avatar {
  margin: 0;
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 100%;
}
.sidebar__online:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #a9a9a9;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}
.sidebar__online_active:before {
  background-color: #27e917;
}

.sidebar__therapist {
  display: flex;
  flex-direction: column;
}

/*noinspection CssInvalidPropertyValue*/
.sidebar__name {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00214e;
  text-wrap: nowrap;
}

@media screen and (max-width: 1300px) {
  .sidebar {
    width: 250px;
  }
  .sidebar__li {
    grid-template-columns: 50px 1fr 26px;
    box-sizing: border-box;
    padding: 8px 15px;
    gap: 16px;
    height: 68px;
  }
  .sidebar__avatar{
    height: 50px;
    width: 50px;
  }
  .sidebar__name {
    font-size: 15px;
    line-height: 22px;
  }
  .sidebar__therapists{
    margin: 21px 0 0;
  }
}
@media screen and (max-width: 750px) {
  .sidebar {
    width: 226px;
  }
  .sidebar__li {
    grid-template-columns: 40px 1fr 18px;
    box-sizing: border-box;
    padding: 9px 15px;
    gap: 10px;
  }
  .sidebar__name {
    font-size: 14px;
  }
  .sidebar__avatar {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 600px) {
  .sidebar {display: none;
  }
  .sidebar__therapists {
    margin: 0;
  }
  .sidebar__online:before {
    width: 10px;
    height: 10px;
  }
  .sidebar__li {
    height: 65px;
    padding: 9px 16px;
    gap: 18px;
  }
  .sidebar__name {
    font-size: 16px;
  }
}
