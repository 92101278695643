.popup {
    width: 28vw;
    min-height: 342px;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
}

.popup__closeBtn {
    width: 16px;
    height: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    background-image: url("../../images/close_icon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin: 24px 24px 0 auto;
}

.popup__title {
    margin: 7px auto 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
    color: #2f42c2;
}

.popup__img {
    width: 145px;
    height: 145px;
    margin: 12px auto 12px;
}

.popup__text {
    color: #121759;
    margin: 12px auto 21px;
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    text-align: center;

}

.popup__nextBtn {
    margin: 0 auto 27px;
    width: 31%;
    height: 57px;
    background: #5d3cc7;
    border: 1px solid #e1e1e1;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.11;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.popup__enterCode {
    list-style-type: none;
    padding: 0;
    margin: 0;
    direction: ltr;
    display: flex;
    gap: 8px;
}

.popup__enterCodeLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #111111;
    margin-bottom: 15px;
}

.popup__number {
    width: 48px;
    height: 55px;
    background: #ffffff;
    border: 1px solid #dbd6ef;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    font-size: 17px;
}

.popup__number:focus {
    background: #dbd6ef;
    border: 1px solid #5d3cc7;
    outline: none;
}

.popup__number::placeholder {
    width: 100%;
    text-align: center;
}



.popup__link {
    margin: 0 3px 0 0;
    font-size: 14px;
    text-decoration: none;
    color: #000000;
    border-bottom: 1px solid #000000;
    transition: all 0.3s;
}

.popup__sendAgain {
    color: #000;
    display: block;
    margin: 27px auto 41px;
    width: 100%;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.popup__sendAgain_underlined {
    text-decoration: underline;
    margin-right: 4px;
    position: relative;
}

.popup__invalid_message {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: #ff0000;
    text-align: center;
    margin: 20px auto 0;
}

.popup__success_message {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: green;
    text-align: center;
    margin: 20px auto 0;
}

.popup__email_wrap {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: baseline;
}

.popup__email_input {
    width: 62%;
    height: 57px;
    background: #ffffff;
    border: 1px solid #dbd6ef;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    font-size: 17px;
}

.popup__email_input:focus {
    background: #dbd6ef;
    border: 1px solid #5d3cc7;
    outline: none;
}

.popup__email_label {
    font-size: 18px;
    margin: 8px auto 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    color: #2f42c2;
}

.popup__email_input_invalid {
    background: #f9a8a852;
    border: 1px solid red;
}

/* hide arrows from number input - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* hide arrows from number input - Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 1800px) {
    .popup__sendAgain {
        margin: 24px auto 19px;
        font-size: 13px;
        line-height: 1;
    }

    .popup__number {
        width: 40px;
        height: 43px;
        font-size: 16px;
    }

    .popup__enterCodeLabel {
        font-size: 14px;
        margin-bottom: 14px;
        margin-top: 0px;
    }

    .popup__text {
        font-size: 17px;
        line-height: 1;
        margin: 12px auto 23px;
    }
}

@media screen and (max-width: 1400px) {
    .login__sendAgain {
        margin: 24px auto 1px;
        font-size: 13px;
        line-height: 1;
    }

    .popup__enterCodeLabel {
        font-size: 11px;
        margin-bottom: 8px;
    }

    .popup__text {
        font-size: 16px;
        line-height: 1;
        margin: 12px auto 23px;
    }
}

@media screen and (max-width: 1300px) {
    .popup {
        min-width: 342px;
        min-height: 342px;
    }

    .popup__closeBtn {
        width: 12px;
        height: 12px;
        margin: 20px 20px 0 auto;
    }

    .popup__title {
        margin-bottom: 5px;
        font-size: 26px;
    }

    .popup__img {
        width: 119px;
        height: 117px;
    }

    .popup__text {
        margin-bottom: 16px;
    }

    .popup__nextBtn {
        height: 47px;
        font-size: 16px;
    }

    .popup__email_input {
        height: 47px;
    }
}

@media screen and (max-width: 600px) {
    .popup {
        max-width: 320px;
        min-height: 322px;
        min-width: 265px;
    }

    .popup__title {
        font-size: 19px;
        margin-bottom: 5px;
    }

    .popup__text {
        font-size: 15px;
        margin-bottom: 43px;
    }

    .popup__img {
        width: 88px;
        height: 87px;
        margin: 0 auto;
    }

    .popup__nextBtn {
        height: 35px;
        font-size: 12px;
        margin-top: 21px;
    }

    .popup__email_input {
        height: 35px;
    }

    .popup__sendAgain {
        font-size: 10px;
        margin-bottom: 12px;
    }

    .popup__enterCodeLabel {
        font-size: 10px;
        line-height: 16px;
    }

    .popup__enterCode {
        gap: 6px;
    }

    .popup__invalid_message {
        font-size: 12px;
        margin-top: 12px;
    }

    .popup__success_message {
        font-size: 12px;
        margin-top: 12px;
    }

    .popup__text {
        font-size: 13px;
        line-height: 1;
        margin: 7px auto 13px;
    }
}